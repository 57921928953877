import { Button } from 'antd';
import './home-page.css';
import { useNavigate } from 'react-router-dom';

export const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 tag-div-height">
          <img src="/icons/empty_icon.svg" alt="icon" className="m-image" />
          <p className="m-text">Hola, Lets begins our journey!</p>
        </div>
      </div>

      <div className="row d-flex justify-content-center">
        <div className="col-sm-2 mt-5">
          <Button
            type="primary"
            size="large"
            style={{ width: '100%' }}
            onClick={() => {
              navigate('job');
            }}
          >
            Let's Start
          </Button>
        </div>
      </div>
    </div>
  );
};
