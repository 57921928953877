import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Alert, FormGroup } from 'reactstrap';

import { signInEnd } from '../../../../api/authAPI';
import { Button } from 'antd';

const SignIn = ({ setOpenCreateUserModal }: any) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: '', sub: '' });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    setOpenCreateUserModal(false);
    window.location.href = '/user';
  }

  function failSubmission(head: any, sub: any) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="invoice-form-div">
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                age: '',
              }}
              validationSchema={Yup.object({
                firstName: Yup.string()
                  .min(2, 'FirstName cannot smaller than 2 characters')
                  .max(50, 'FirstName cannot exceed 50 characters')
                  .required('Required'),
                lastName: Yup.string()
                  .min(2, 'LastName cannot smaller than 2 characters')
                  .max(50, 'LastName cannot exceed 50 characters')
                  .required('Required'),
                age: Yup.string()
                  .min(2, 'Age cannot smaller than 1 characters')
                  .max(50, 'Age cannot exceed 5 characters')
                  .required('Required'),
                email: Yup.string().max(100, 'Email cannot exceed 100 characters').email('Invalid Email').required('Required'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                signInEnd({
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  age: values.age,
                }).then((data) => {
                  try {
                    if (data['result']) {
                      if (data['result'] === 'SUCCESS') {
                        successSubmission();
                      } else if (data['result'] === 'FAILED') {
                        failSubmission('¡Error!', data['data']['errorMessage']);
                      } else {
                        failSubmission('Error!', 'Please try again later!');
                      }
                    } else {
                      failSubmission('Error!', 'Please try again later!');
                    }
                  } catch (error) {
                    failSubmission('Error!', 'Please try again later!');
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mt-4 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field name="firstName" type="text" className="form-control" placeholder="First Name" disabled={isLoading} />
                          <ErrorMessage name="firstName">{(msg: any) => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field name="lastName" type="text" className="form-control" placeholder="Last Name" disabled={isLoading} />
                          <ErrorMessage name="lastName">{(msg: any) => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field name="age" type="text" className="form-control" placeholder="Age" disabled={isLoading} />
                          <ErrorMessage name="age">{(msg: any) => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field name="email" type="text" className="form-control" placeholder="Email" disabled={isLoading} />
                          <ErrorMessage name="email">{(msg: any) => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                {/* <p>{errorMsg.head}</p>
                                <hr /> */}
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <Button type="primary" htmlType="submit" size="large" style={{ width: '100%' }} loading={isLoading}>
                            Create New User
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
