import { Button, DatePicker, DatePickerProps } from 'antd';

import { useEffect, useState } from 'react';

import { PlanExport } from '../../../api/planAPI';
import { openNotification } from '../../../meta/globalToaster';
import { NOTIFICATION_TYPES } from '../../../const/notificationTypes';
import { ExportPartComponent } from '../../../components/part-component/ExportPartComponent';
import { MessageComponent } from '../../../components/message-component/MessageComponent';
import dayjs from 'dayjs';
import moment from 'moment';

export const PlanExportComponent = ({ installedDate, partItems, pdfExportSuccess }: any) => {
  const [partItemList, setPartItemList] = useState<any>([]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  const [selectedImgList, setSelectedImgList] = useState<any>([]);
  const [selectedIdList, setSelectedIdList] = useState<any>([]);

  const [printedDate, setPrintedDate] = useState(installedDate);

  useEffect(() => {
    if (partItems && partItems?.length > 0) {
      setPartItemList(partItems);
      setIsEmpty(false);
    } else {
      setPartItemList([]);
      setIsEmpty(true);
    }
  }, [partItems]);

  const onChangeSelect = (value: string, imgUrl: any, id: any) => {
    if (value) {
      setSelectedImgList([...selectedImgList, imgUrl]);
      setSelectedIdList([...selectedIdList, id]);
    } else {
      var index = selectedImgList.indexOf(imgUrl);
      if (index > -1) {
        selectedImgList.splice(index, 1);
      }

      var indexI = selectedIdList.indexOf(id);
      if (indexI > -1) {
        selectedIdList.splice(indexI, 1);
      }
    }
  };

  const successSubmission = (data: any) => {
    setSubmitLoading(false);

    if (data['file']) {
      // openNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: 'Downloaded' });
      setSelectedImgList([]);
      setSelectedIdList([]);
      pdfExportSuccess(data['file']);

      // window.location = data['file'];
    } else {
      openNotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Something went wrong' });
    }
  };

  const failSubmission = (message: any) => {
    setSubmitLoading(false);
    openNotification({ message });
  };

  const updateExportClick = async () => {
    if (selectedImgList?.length > 0) {
      setSubmitLoading(true);
      PlanExport({
        imgList: selectedImgList,
        idList: selectedIdList,
        printedDate: moment(printedDate).format('YYYY-MM-DD'),
      }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              successSubmission(data['data']);
            } else if (data['result'] === 'FAILED') {
              failSubmission(data['data']['errorMessage']);
            } else {
              failSubmission('Please try again later!');
            }
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      openNotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Select at least one Part' });
    }
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setPrintedDate(dateString);
  };

  return (
    <div>
      <>
        {isEmpty ? (
          <MessageComponent />
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-sm-8 mb-2"></div>
              <div className="col-sm-3 mb-2">
                Installed Date:{' '}
                <DatePicker
                  defaultValue={(installedDate && dayjs(installedDate, 'YYYY-MM-DD')) || null}
                  onChange={onChange}
                  disabledDate={(current) => {
                    let customDate = moment().format('YYYY-MM-DD');
                    return current && current < moment(customDate, 'YYYY-MM-DD');
                  }}
                  placeholder="Printed Date"
                />
              </div>
              <div className="col-sm-1 mb-2">
                <Button
                  style={{ float: 'right' }}
                  type="primary"
                  //   icon={<PoweroffOutlined />}
                  loading={submitLoading}
                  onClick={() => updateExportClick()}
                >
                  Export PDF
                </Button>
              </div>
            </div>
            <div className="row print-part-list-div">
              {partItemList?.map((item: any, i: any) => {
                return (
                  <div key={i} className="col-sm-2 m-0 p-1">
                    <ExportPartComponent item={item} onChangeSelect={onChangeSelect} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </>
    </div>
  );
};
