import { createRef, useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { Button, Tag } from 'antd';

import './part-component.css';

export const PartComponentV1 = ({ item, partOnclick, clickDeletePart, selectedPart }: any) => {
  const imgRef: any = createRef();

  const [isActive, setIsActive] = useState(false);
  const [isImgHeightLarge, setIsImgHeightLarge] = useState(false);

  const partOnClick = () => {
    const tempActive = isActive;

    setIsActive(!tempActive);
    partOnclick(item);
  };

  useEffect(() => {
    if (imgRef?.current?.clientHeight && imgRef?.current?.clientWidth) {
      if (imgRef?.current?.clientHeight > imgRef?.current?.clientWidth) {
        setIsImgHeightLarge(true);
      } else {
        setIsImgHeightLarge(false);
      }
    }
  }, [imgRef]);

  return (
    <div
      //   className={`a-part-img-div`}
      onClick={() => {
        partOnClick();
      }}
    >
      {item?.isPrinted === 1 && (
        <Tag className="printed-label" color="black">
          Printed
        </Tag>
      )}

      <Button
        className="p-delete-button"
        danger={true}
        icon={<MdDelete />}
        onClick={(e) => {
          e.stopPropagation();
          clickDeletePart(item?.id);
        }}
      />
      <div className={`${selectedPart?.id === item?.id ? 'part-img-active-div' : ''}`}></div>
      <img className="part-img-tag" src={item?.imgUrl} alt="part" />
    </div>
  );
};
