import { useNavigate, useParams } from 'react-router-dom';

import { createRef, useEffect, useRef, useState } from 'react';
import { UpdateJob, deleteJobApi, readByJobId } from '../../../api/jobAPI';
import { Button, Modal, Select, Tag, Input, Image, Carousel } from 'antd';

import {
  PlusCircleOutlined,
  PrinterOutlined,
  ArrowLeftOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { deleteAreaAPI, readActiveAreasByJob } from '../../../api/areaAPI';

import AddAreaForm from '../../area/forms/AddAreaForm';
import { deletePartAPI, getPartList, readPartByIdAPI, updatePartDataAPI } from '../../../api/partAPI';
import { TagsInput } from 'react-tag-input-component';
import { openNotification } from '../../../meta/globalToaster';
import { NOTIFICATION_TYPES } from '../../../const/notificationTypes';
import AddPlanForm from '../../plan/form/AddPlanForm';
import { PlanExportComponent } from '../../plan/plan-export/PlanExportComponent';
import { PartComponent } from '../../../components/part-component/PartComponent';
import { MessageComponent } from '../../../components/message-component/MessageComponent';
import { LoadingComponent } from '../../../components/loading-component/LoadingComponent';
import { PdfViewerComponent } from '../../../components/PdfViewerComponent';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import EditAreaForm from '../../area/forms/EditAreaForm';
import { PartDeleteComponent } from '../../plan/plan-export/PartDeleteComponent';

import './job-page.css';
import { PartComponentV1 } from '../../../components/part-component/PartComponentV1';

const { TextArea } = Input;

const contentStyle: React.CSSProperties = {
  margin: 0,
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

export const JobPage = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();

  const jobEditInputRefRef: any = useRef(null);

  const headerRef: any = createRef();
  const areaRef: any = createRef();
  const sideHeaderRef: any = createRef();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [areaHeight, setAreaHeight] = useState(0);
  const [sideHeaderHeight, setSideHeaderHeight] = useState(0);

  const [jobData, setJobData] = useState<any>({});
  const [loading, setLoading] = useState(true);

  const [areaItems, setAreaItems] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const [loadingArea, setAreaLoading] = useState(true);
  const [selectedArea, setSelectedArea] = useState<any>('');
  const [openCreateAreaModal, setOpenCreateAreaModal] = useState(false);
  const [toggleNewArea, setToggleNewArea] = useState(false);

  const [partPending, setPartPending] = useState(true);
  const [partItems, setPartItems] = useState([]);

  const [selectedPart, setSelectedPart] = useState<any>({});
  const [selectedPartArea, setSelectedPartArea] = useState(null);
  const [selectedPartTags, setSelectedPartTags] = useState([]);
  const [selectedPartNote, setSelectedPartNote] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  const [openRightSidebar, setOpenRightSidebar] = useState(true);

  const [isOpenAddPlanModal, setIsOpenAddPlanModal] = useState(false);
  const [refreshParts, setRefreshParts] = useState(false);

  const [isOpenExportPlanModal, setIsOpenExportPlanModal] = useState(false);

  const [isOpenDeletePartModal, setIsOpenDeletePartModal] = useState(false);
  const [deletePartId, setDeletePartId] = useState(null);

  const [isOpenDeleteAreaModal, setIsOpenDeleteAreaModal] = useState(false);
  const [deleteAreaId, setDeleteAreaId] = useState(null);

  const [isOpenEditAreaModal, setIsOpenEditAreaModal] = useState(false);
  const [editAreaItem, setEditAreaItem] = useState(null);

  const [isOpenPdfPreviewModal, setIsOpenPdfPreviewModal] = useState(false);
  const [exportedUrl, setExportedUrl] = useState(null);

  const [moreDetails, setMoreDetails] = useState(false);

  const [currentJobName, setCurrentJobName] = useState('');
  const [isEditingJobName, setIsEditingJobName] = useState(false);

  const [isOpenDeleteJobModal, setIsOpenDeleteJobModal] = useState(false);

  const [isLinkedPartClick, setIsLinkedPartClick] = useState(false);
  const [isOpenPartDeleteModal, setIsOpenPartDeleteModal] = useState(false);

  useEffect(() => {
    if (headerRef) {
      setHeaderHeight(headerRef?.current?.clientHeight);
    }

    if (areaRef) {
      setAreaHeight(areaRef?.current?.clientHeight);
    }

    if (sideHeaderRef) {
      setSideHeaderHeight(sideHeaderRef?.current?.clientHeight);
    }
  }, [headerRef, areaRef, sideHeaderRef]);

  useEffect(() => {
    setLoading(true);
    if (jobId) {
      readByJobId({ id: jobId }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              setJobData(data['data']);

              if (data['data']?.name) {
                setCurrentJobName(data['data']?.name);
              }

              setLoading(false);
            } else if (data['result'] === 'FAILED') {
              setLoading(false);
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      });
    }
  }, [jobId, refreshParts]);

  useEffect(() => {
    if (jobId) {
      setAreaLoading(true);

      readActiveAreasByJob({ jobId }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              setAreaItems(data['data']);

              let tempAreaList: any = [{ value: 0, label: 'Not Specified' }];
              data['data']?.map((item: any) => {
                tempAreaList.push({ value: item?.id, label: item?.name });
              });

              setAreaList(tempAreaList);

              setAreaLoading(false);
            } else if (data['result'] === 'FAILED') {
              setAreaLoading(false);
            } else {
              setAreaLoading(false);
            }
          } else {
            setAreaLoading(false);
          }
        } catch (error) {
          setAreaLoading(false);
        }
      });
    }
  }, [jobId, toggleNewArea]);

  useEffect(() => {
    getJobPartData();

    // const interval = setInterval(() => {
    //   getJobPartData();
    // }, MINUTE_MS);

    // return () => clearInterval(interval);
  }, [jobId, selectedArea, refreshParts]);

  const getJobPartData = () => {
    setPartPending(true);
    getPartList({ jobId: jobId, areaId: selectedArea }).then((data) => {
      try {
        if (data['result']) {
          if (data['result'] === 'SUCCESS') {
            setPartItems(data['data']);

            if (!isLinkedPartClick && data['data']?.length > 0) {
              setSelectedPart(data['data'][0]);

              setIsLinkedPartClick(false);
            }

            setPartPending(false);
          } else if (data['result'] === 'FAILED') {
            setPartItems([]);
            setPartPending(false);
          } else {
            setPartPending(false);
          }
        } else {
          setPartItems([]);
          setPartPending(false);
        }
      } catch (error) {
        setPartItems([]);
        setPartPending(false);
      }
    });
  };

  const successSubmission = (message: any) => {
    setToggleNewArea(!toggleNewArea);

    setSubmitLoading(false);
    openNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: message });
  };

  const failSubmission = (message: any) => {
    setSubmitLoading(false);
    setIsEditingJobName(false);
    openNotification({ message });
  };

  const updateJobSuccess = () => {
    setSubmitLoading(false);
    setIsEditingJobName(false);
    openNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: 'Job Name Updated' });
  };

  const addAreaSuccess = () => {
    setOpenCreateAreaModal(false);
    setToggleNewArea(!toggleNewArea);
  };

  const updatePartData = () => {
    if (selectedPart?.id) {
      setSubmitLoading(true);

      updatePartDataAPI({
        partId: selectedPart?.id,
        areaId: selectedPartArea,
        tags: selectedPartTags,
        note: selectedPartNote,
      }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              successSubmission('Part Updated');
            } else if (data['result'] === 'FAILED') {
              failSubmission(data['data']['errorMessage']);
            } else {
              failSubmission('Please try again later!');
            }
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      failSubmission('Please select a part!');
    }
  };

  const updatedPlanList = () => {
    setIsOpenAddPlanModal(false);

    setRefreshParts(!refreshParts);
    setToggleNewArea(!toggleNewArea);
  };

  const clickDeletePart = (areaId: any) => {
    setIsOpenDeletePartModal(true);
    setDeletePartId(areaId);
  };

  const deletePartConfirm = () => {
    if (deletePartId) {
      setSubmitLoading(true);
      deletePartAPI({
        partId: deletePartId,
      }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              successSubmission('Part Deleted');
              setIsOpenDeletePartModal(false);

              setRefreshParts(!refreshParts);
              setToggleNewArea(!toggleNewArea);
            } else if (data['result'] === 'FAILED') {
              failSubmission(data['data']['errorMessage']);
            } else {
              failSubmission('Please try again later!');
            }
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      failSubmission('Area Required.');
    }
  };

  const clickDeleteJob = () => {
    setIsOpenDeleteJobModal(true);
  };

  const deleteJobConfirm = () => {
    if (jobId) {
      setSubmitLoading(true);
      deleteJobApi({
        jobId,
      }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              successSubmission('Job Deleted');
              setIsOpenDeleteJobModal(false);

              navigate(`${'/job'}`);
            } else if (data['result'] === 'FAILED') {
              failSubmission(data['data']['errorMessage']);
            } else {
              failSubmission('Please try again later!');
            }
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      failSubmission('Job Required.');
    }
  };

  const clickDeleteArea = (areaId: any) => {
    setIsOpenDeleteAreaModal(true);
    setDeleteAreaId(areaId);
  };

  const deleteAreaConfirm = () => {
    if (deleteAreaId) {
      setSubmitLoading(true);
      deleteAreaAPI({
        areaId: deleteAreaId,
      }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              successSubmission('Area Deleted');
              setIsOpenDeleteAreaModal(false);

              setRefreshParts(!refreshParts);
              setToggleNewArea(!toggleNewArea);
            } else if (data['result'] === 'FAILED') {
              failSubmission(data['data']['errorMessage']);
            } else {
              failSubmission('Please try again later!');
            }
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      failSubmission('Area Required.');
    }
  };

  const pdfExportSuccess = (pdfUrl: any) => {
    setIsOpenExportPlanModal(false);
    setRefreshParts(!refreshParts);

    if (pdfUrl) {
      setExportedUrl(pdfUrl);
      setIsOpenPdfPreviewModal(true);
    }
  };

  const partDeleteSuccess = () => {
    setIsOpenPartDeleteModal(false);
    setRefreshParts(!refreshParts);
  };

  const editAreaClick = (item: any) => {
    setIsOpenEditAreaModal(true);
    setEditAreaItem(item);
  };

  const editAreaSuccess = () => {
    setIsOpenEditAreaModal(false);
    setEditAreaItem(null);
    setToggleNewArea(!toggleNewArea);
  };

  const partOnclick = (item: any) => {
    setMoreDetails(false);
    setOpenRightSidebar(true);
    setSelectedPart(item);

    setSelectedPartArea(item?.areaId);
    setSelectedPartNote(item?.note);

    if (item?.tags) {
      let tempItemArray = item?.tags?.split(',');
      setSelectedPartTags(tempItemArray);
    } else {
      setSelectedPartTags([]);
    }
  };

  const linkedPartClick = async (item: any) => {
    setIsLinkedPartClick(true);

    if (item?.id) {
      readPartByIdAPI({ jobId, partId: item?.id }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              const tempPart = data['data'];
              setOpenRightSidebar(true);
              setSelectedPart(tempPart);

              setSelectedPartArea(tempPart?.areaId);
              setSelectedPartNote(tempPart?.note);
              setSelectedArea(tempPart?.areaId);

              if (tempPart?.tags) {
                let tempItemArray = tempPart?.tags?.split(',');
                setSelectedPartTags(tempItemArray);
              } else {
                setSelectedPartTags([]);
              }
            } else if (data['result'] === 'FAILED') {
              failSubmission(data['data']['errorMessage']);
            } else {
              failSubmission('Please try again later!');
            }
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      failSubmission('Id Required.');
    }
  };

  const updateJobButtonClick = () => {
    if (currentJobName !== null && currentJobName !== '') {
      setSubmitLoading(true);
      UpdateJob({
        name: currentJobName,
        jobId: jobId,
      }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              updateJobSuccess();
            } else if (data['result'] === 'FAILED') {
              failSubmission(data['data']['errorMessage']);
            } else {
              failSubmission('Please try again later!');
            }
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      failSubmission('Job Name Required.');
    }
  };

  const handleClickOutside = (e: any) => {
    if (jobEditInputRefRef.current && !jobEditInputRefRef.current.contains(e.target)) {
      setIsEditingJobName(false);
      updateJobButtonClick();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [currentJobName]);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-9 p-3 middle-div">
              <div ref={headerRef} className="job-title-div">
                <div className="row">
                  <div className="col-sm-12 center-v-div">
                    <Button
                      className="back-button"
                      type="primary"
                      icon={<ArrowLeftOutlined />}
                      loading={false}
                      onClick={() => navigate(`${'/job'}`)}
                    ></Button>

                    {isEditingJobName ? (
                      <div className="job-input-outer-div" ref={jobEditInputRefRef}>
                        <Input
                          className="a-input-box"
                          value={currentJobName}
                          placeholder="Job name..."
                          onChange={(e) => {
                            setCurrentJobName(e.target.value);
                          }}
                          disabled={submitLoading}
                          onPressEnter={(e) => {
                            updateJobButtonClick();

                            e.preventDefault();
                          }}
                        />
                      </div>
                    ) : (
                      <h2
                        className="job-title"
                        onClick={() => {
                          setIsEditingJobName(true);
                        }}
                      >
                        {currentJobName}
                      </h2>
                    )}

                    {isEditingJobName ? (
                      <>
                        {/* <Button
                          className="back-button update-job-btn"
                          type="primary"
                          icon={<CheckOutlined />}
                          loading={false}
                          onClick={() => updateJobButtonClick()}
                        ></Button> */}
                      </>
                    ) : (
                      <>
                        <Button
                          className="back-button edit-job-btn"
                          type="primary"
                          icon={<EditOutlined />}
                          loading={false}
                          onClick={() => setIsEditingJobName(true)}
                        ></Button>
                        <Button
                          className="back-button delete-job-btn"
                          type="primary"
                          icon={<DeleteOutlined />}
                          loading={false}
                          onClick={() => clickDeleteJob()}
                        ></Button>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="container-fluid row mt-2">
                <div className="col-sm-12 center-v-div">
                  <h1 className="sub-page-title">Areas</h1>

                  <Button
                    icon={<PlusCircleOutlined />}
                    className="job-add-button"
                    onClick={() => {
                      setOpenCreateAreaModal(true);
                    }}
                    disabled={loading}
                  >
                    Add
                  </Button>
                </div>
              </div>

              <div
                ref={areaRef}
                className="container-fluid job-area-div"
                style={{ maxHeight: `calc(50vh - ${headerHeight + 100}px)` }}
              >
                <div className="row mt-2">
                  <div className="col-sm-12 white-card">
                    <div className="row">
                      <div className="col-sm-3 m-0 p-1">
                        <Button
                          size="large"
                          className={`area-button ${selectedArea === '' || selectedArea === 0 ? 'active-button' : ''}`}
                          onClick={() => setSelectedArea('')}
                        >
                          <Tag className="a-label" bordered={false}>
                            {jobData?.numOfParts}
                          </Tag>

                          <span>All</span>
                        </Button>
                      </div>
                      {loadingArea ? (
                        <LoadingComponent />
                      ) : (
                        <>
                          {areaItems?.map((item: any, i: any) => {
                            return (
                              <div className="col-sm-3 m-0 p-1" key={i}>
                                <Button
                                  size="large"
                                  className={`area-button ${selectedArea === item?.id ? 'active-button' : ''}`}
                                  onClick={() => {
                                    setSelectedArea(item?.id);
                                    setIsLinkedPartClick(false);
                                  }}
                                >
                                  <Button
                                    className="a-edit-button"
                                    icon={<MdModeEdit />}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      editAreaClick(item);
                                    }}
                                  ></Button>

                                  <Button
                                    className="a-delete-button"
                                    danger={true}
                                    icon={<MdDelete />}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      clickDeleteArea(item?.id);
                                    }}
                                  ></Button>

                                  <Tag className="a-label" bordered={false}>
                                    {item?.numOfParts}
                                  </Tag>

                                  <span>{item?.name}</span>
                                </Button>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid row mt-3 mb-2">
                <div className="col-sm-12 center-v-div">
                  <h1 className="sub-page-title">Parts</h1>

                  <Button
                    icon={<PlusCircleOutlined />}
                    className="job-add-button"
                    onClick={() => {
                      setIsOpenAddPlanModal(true);
                    }}
                    disabled={loading}
                  >
                    Add
                  </Button>

                  <Button
                    icon={<PrinterOutlined />}
                    className="job-add-button"
                    onClick={() => {
                      setIsOpenExportPlanModal(true);
                    }}
                    disabled={loading}
                  >
                    Print
                  </Button>

                  <Button
                    icon={<DeleteOutlined />}
                    className="part-delete-button"
                    onClick={() => {
                      setIsOpenPartDeleteModal(true);
                    }}
                    disabled={loading}
                  >
                    Bulk delete
                  </Button>
                </div>
              </div>

              <div
                className="container-fluid job-part-div"
                style={{ maxHeight: `calc(100vh - ${areaHeight + headerHeight + 145}px)` }}
              >
                <div className="row">
                  <div className="col-sm-12 white-card">
                    {partPending ? (
                      <LoadingComponent />
                    ) : (
                      <div className="row">
                        {partItems?.length > 0 ? (
                          <div className="col-sm-12">
                            <div className="grid">
                              {partItems?.map((item: any, i: any) => {
                                return (
                                  <div key={i}>
                                    <PartComponentV1
                                      item={item}
                                      partOnclick={partOnclick}
                                      clickDeletePart={clickDeletePart}
                                      selectedPart={selectedPart}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : (
                          <MessageComponent />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3 p-0">
              {openRightSidebar && (
                <div className="right-side-bar p-2">
                  {partItems?.length > 0 ? (
                    <div className="container-fluid">
                      <div ref={sideHeaderRef} className="row pt-3">
                        <div className="col-sm-10">
                          <p className="s-part-title">{`Part ${selectedPart?.name}`}</p>
                        </div>
                        <div className="col-sm-2 icon-next-btn">
                          <Button
                            onClick={() => {
                              setMoreDetails(!moreDetails);
                            }}
                            icon={moreDetails ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
                          />
                        </div>
                      </div>

                      {moreDetails ? (
                        <>
                          <div className="row mt-4">
                            <div className="col-sm-12">
                              <p className="s1-part-title">Details</p>
                            </div>
                            <div className="col-sm-12 mb-1">
                              {!loadingArea && (
                                <Select
                                  size="large"
                                  style={{ width: '100%' }}
                                  placeholder="Area not specified"
                                  options={areaList}
                                  onChange={(value: any) => setSelectedPartArea(value)}
                                  value={selectedPartArea ? selectedPartArea : null}
                                />
                              )}
                            </div>

                            <div className="col-sm-12 mb-1">
                              {
                                <TagsInput
                                  value={selectedPartTags}
                                  onChange={(value: any) => setSelectedPartTags(value)}
                                  placeHolder="Add tags..."
                                />
                              }
                            </div>

                            <div className="col-sm-12 mb-1">
                              {
                                <TextArea
                                  value={selectedPartNote}
                                  onChange={(e) => setSelectedPartNote(e?.target?.value)}
                                  placeholder="Notes..."
                                  rows={3}
                                  // autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                              }
                            </div>

                            <div className="col-sm-12 mt-2 mb-1">
                              <Button
                                type="primary"
                                style={{ width: '100%' }}
                                onClick={() => {
                                  updatePartData();
                                }}
                                loading={submitLoading}
                              >
                                Update Data
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          className="container right-side-bar-first mt-2"
                          style={{ maxHeight: `calc(100vh - ${sideHeaderHeight + 40}px)` }}
                        >
                          <div className="row mt-2">
                            <div className="col-sm-12">
                              <Carousel>
                                <div>
                                  <div className="row mt-2">
                                    <div className="col-sm-12">
                                      <div className="part-img-item-div">
                                        {selectedPart?.imgUrl ? (
                                          <Image className="part-item-img" src={selectedPart?.imgUrl} alt="part" />
                                        ) : (
                                          <MessageComponent />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {selectedPart?.isolatedImgUrl && (
                                  <div>
                                    <div className="row mt-2">
                                      <div className="col-sm-12">
                                        <div className="part-img-item-div">
                                          <Image
                                            className="part-item-img"
                                            src={selectedPart?.isolatedImgUrl}
                                            alt="part"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Carousel>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-sm-12">
                              <p className="s1-part-title">Linked Parts</p>
                            </div>
                            <div className="col-sm-12">
                              {selectedPart?.linkedParts && selectedPart?.linkedParts?.length ? (
                                <>
                                  {selectedPart?.linkedParts?.map((item: any, index: any) => {
                                    return (
                                      <Tag
                                        className="link-label"
                                        bordered={false}
                                        key={index}
                                        onClick={() => {
                                          linkedPartClick(item);
                                        }}
                                      >
                                        {item?.name}
                                      </Tag>
                                    );
                                  })}
                                </>
                              ) : (
                                <Tag className="link-label" bordered={false}>
                                  None
                                </Tag>
                              )}
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-sm-12">
                              <p className="s1-part-title">Reference</p>
                            </div>
                            <div className="col-sm-12">
                              <div className="part-img-item-div">
                                {selectedPart?.refUrl ? (
                                  <Image className="part-item-img" src={selectedPart?.refUrl} alt="part" />
                                ) : (
                                  <MessageComponent />
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2 pb-4">
                            <div className="col-sm-12">
                              <p className="s1-part-title">Page</p>
                            </div>
                            <div className="col-sm-12">
                              <div className="part-img-item-div">
                                {selectedPart?.pageUrl ? (
                                  <Image className="part-item-img" src={selectedPart?.pageUrl} alt="part" />
                                ) : (
                                  <MessageComponent />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <MessageComponent />
                  )}
                </div>
              )}
            </div>
          </div>

          <Modal
            width={'80vw'}
            title="Add area"
            centered
            open={openCreateAreaModal}
            onCancel={() => setOpenCreateAreaModal(false)}
            footer={null}
          >
            <AddAreaForm jobId={jobId} addAreaSuccess={addAreaSuccess} />
          </Modal>

          {/* Edit Area */}
          <Modal
            title="Edit Area"
            centered
            open={isOpenEditAreaModal}
            onCancel={() => setIsOpenEditAreaModal(!isOpenEditAreaModal)}
            footer={null}
          >
            <EditAreaForm clickedArea={editAreaItem} editAreaSuccess={editAreaSuccess} />
          </Modal>

          {/* Add New Plan */}
          <Modal
            title="Add parts"
            centered
            open={isOpenAddPlanModal}
            onCancel={() => setIsOpenAddPlanModal(!isOpenAddPlanModal)}
            footer={null}
          >
            <AddPlanForm jobId={jobId} updatedPlanList={updatedPlanList} />
          </Modal>

          {/* Print parts */}
          <Modal
            width={'80vw'}
            title="Print parts"
            centered
            open={isOpenExportPlanModal}
            onCancel={() => {
              pdfExportSuccess('');
            }}
            footer={null}
          >
            <PlanExportComponent
              jobId={jobId}
              installedDate={jobData?.installedDate}
              partItems={partItems}
              pdfExportSuccess={pdfExportSuccess}
            />
          </Modal>

          {/* Delete Job */}
          <Modal
            title="Confirm"
            centered
            open={isOpenDeleteJobModal}
            onCancel={() => setIsOpenDeleteJobModal(!isOpenDeleteJobModal)}
            okText="Yes"
            onOk={() => {
              deleteJobConfirm();
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <ExclamationCircleOutlined style={{ color: 'orange', fontSize: '40px', marginTop: '10px' }} />
              <p className="warning-message mt-2 mb-3">Do you need to delete this job?</p>
            </div>
          </Modal>

          {/* Delete Part */}
          <Modal
            title="Confirm"
            centered
            open={isOpenDeletePartModal}
            onCancel={() => setIsOpenDeletePartModal(!isOpenDeletePartModal)}
            okText="Yes"
            onOk={() => {
              deletePartConfirm();
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <ExclamationCircleOutlined style={{ color: 'orange', fontSize: '40px', marginTop: '10px' }} />
              <p className="warning-message mt-2 mb-3">Do you need to delete this part?</p>
            </div>
          </Modal>

          {/* Delete Area*/}
          <Modal
            title="Confirm"
            centered
            open={isOpenDeleteAreaModal}
            onCancel={() => setIsOpenDeleteAreaModal(!isOpenDeleteAreaModal)}
            okText="Yes"
            onOk={() => {
              deleteAreaConfirm();
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <ExclamationCircleOutlined style={{ color: 'orange', fontSize: '40px', marginTop: '10px' }} />
              <p className="warning-message mt-2 mb-3">Do you need to delete this area?</p>
            </div>
          </Modal>

          {
            <Modal
              width={'80vw'}
              title="Exported PDF"
              centered
              open={isOpenPdfPreviewModal}
              onCancel={() => setIsOpenPdfPreviewModal(!isOpenPdfPreviewModal)}
              footer={null}
            >
              <PdfViewerComponent url={exportedUrl} />
            </Modal>
          }

          {/* Delete parts */}
          <Modal
            width={'80vw'}
            title="Delete parts"
            centered
            open={isOpenPartDeleteModal}
            onCancel={() => {
              partDeleteSuccess();
            }}
            footer={null}
          >
            <PartDeleteComponent jobId={jobId} partItems={partItems} partDeleteSuccess={partDeleteSuccess} />
          </Modal>
        </div>
      )}
    </>
  );
};
