import axios from 'axios';

export const loginEnd = async ({ email, password }: any) => {
  try {
    let formData: any = new FormData();

    formData.append('email', email);
    formData.append('password', password);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/auth/login.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const signInEnd = async ({ firstName, lastName, email, age }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('age', age);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/auth/create_user.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const tokenCheck = async ({ token }: any) => {
  try {
    let formData: any = new FormData();

    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/auth/validate_token.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readAllUsers = async () => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/auth/read_all.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const editUserEnd = async ({
  firstName,
  lastName,
  birthDate,
  phoneNumber,
  password,
  newPassword,
  confirmNewPassword,
}: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('birthDate', birthDate);
    formData.append('phoneNumber', phoneNumber);
    formData.append('oldPassword', password);
    formData.append('password', newPassword);
    formData.append('confPassword', confirmNewPassword);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/auth/update_user.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const updateUserRoleState = async ({ uniqueId, role, active }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('uniqueId', uniqueId);
    formData.append('role', role);
    formData.append('active', active);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/auth/update_user_role_state.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readByUserId = async ({ userId }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('token', token);
    formData.append('userId', userId);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/auth/read_by_id.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getCurrentPlayerList = async (word: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('q', word);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/auth/read_current_player_list.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getCurrentScorerList = async (word: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('q', word);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/auth/read_current_scorer_list.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
