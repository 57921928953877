import React, { useEffect, useState } from 'react';
import { Button, Input, Spin } from 'antd';

import { partsByArea } from '../../../api/partAPI';

import { AreaPartComponent } from '../../../components/part-component/AreaPartComponent';
import { AddArea } from '../../../api/areaAPI';
import { openNotification } from '../../../meta/globalToaster';

import './area-form.css';
import { LoadingComponent } from '../../../components/loading-component/LoadingComponent';

const AddAreaForm = ({ jobId, addAreaSuccess }: any) => {
  const [loading, setLoading] = useState(true);
  const [successToggle, setSuccessToggle] = useState(true);

  const [partList, setPartList] = useState([]);
  const [areaName, setAreaName] = useState('');

  const [selectedParts, setSelectedParts] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    if (jobId) {
      partsByArea({ jobId, areaId: 0 }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              setPartList(data['data']);

              setLoading(false);
            } else if (data['result'] === 'FAILED') {
              setLoading(false);
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      });
    }
  }, [jobId, successToggle]);

  const onChangeSelect = (value: string, id: any) => {
    if (value) {
      setSelectedParts([...selectedParts, id]);
    } else {
      var index = selectedParts.indexOf(id);
      if (index > -1) {
        selectedParts.splice(index, 1);
      }
    }
  };

  const successSubmission = () => {
    setLoading(false);
    setSelectedParts([]);
    setSuccessToggle(!successToggle);
    setAreaName('');

    addAreaSuccess();
  };

  const failSubmission = (message: any) => {
    setLoading(false);
    openNotification({ message });
  };

  const addAreaButtonClick = () => {
    if (areaName !== null && areaName !== '') {
      setLoading(true);
      AddArea({
        name: areaName,
        jobId: jobId,
        partList: selectedParts,
      }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              successSubmission();
            } else if (data['result'] === 'FAILED') {
              failSubmission(data['data']['errorMessage']);
            } else {
              failSubmission('Please try again later!');
            }
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      failSubmission('Area Required.');
    }
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12 mt-3 mb-3">
              <p className="a-input-label">Name</p>
              <Input
                value={areaName}
                className="a-input-box"
                placeholder="Area name..."
                onChange={(e) => {
                  setAreaName(e.target.value);
                }}
                disabled={loading}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mb-3">
              <div className="a-div">
                <p className="a-input-label">Include existing parts (Optional)</p>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="row">
              <div className="col-sm-12">
                <LoadingComponent />
              </div>
            </div>
          ) : (
            <div className="row add-area-parts">
              {partList?.length > 0 ? (
                <div className="col-sm-12">
                  <div className="row">
                    {partList?.map((item: any, i: any) => {
                      return (
                        <div className="col-sm-2" key={i}>
                          <AreaPartComponent item={item} onChangeSelect={onChangeSelect} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="col-sm-12">No Data</div>
              )}
            </div>
          )}

          <div className="row d-flex justify-content-end">
            <div className="col-sm-2 mt-3">
              <Button
                type="primary"
                size="large"
                style={{ width: '100%' }}
                // loading={loading}
                onClick={() => {
                  addAreaButtonClick();
                }}
                loading={loading}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAreaForm;
