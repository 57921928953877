import axios from 'axios';

export const readJobs = async ({ f }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('token', token);
    formData.append('f', f);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/job/list.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const AddJob = async ({ name, installedDate }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('name', name);
    formData.append('installedDate', installedDate);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/job/create.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readByJobId = async ({ id }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('id', id);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/job/read_by_id.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readJobsSearch = async ({ f }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('token', token);
    formData.append('f', f);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/job/search.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readJobsCount = async ({ f, searchJobWord }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('token', token);
    formData.append('f', f);
    formData.append('searchJobWord', searchJobWord);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/job/count.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readJobsByLimit = async ({ f, start, length, searchJobWord }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('token', token);
    formData.append('f', f);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('searchJobWord', searchJobWord);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/job/render.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const UpdateJob = async ({ jobId, name }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('jobId', jobId);
    formData.append('name', name);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/job/update.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const deleteJobApi = async ({ jobId }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('jobId', jobId);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/job/delete.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
