import { createRef, useEffect, useState } from 'react';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { PlusCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { readJobsByLimit } from '../../../api/jobAPI';

import AddJobForm from '../form/AddJobForm';
import { Button, Input, Pagination, PaginationProps } from 'antd';
import { dateFormatWithDate } from '../../../utils/fomatter';
import { JobRowLoadingComponent } from '../../../components/loading-component/LoadingComponent';
import { MAX_JOBS_PER_PAGE } from '../../../const/other';

import './job-list.css';

const { Search } = Input;

const JobList = () => {
  const navigate = useNavigate();
  const headerRef: any = createRef();
  const [headerHeight, setHeaderHeight] = useState(0);

  const [jobItems, setJobItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchJobWord, setSearchJobWord] = useState('');
  const [searchToggle, setSearchToggle] = useState(true);

  const [openCreateJobModal, setOpenCreateJobModal] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLoading(true);

    readJobsByLimit({
      f: 'U',
      start: (currentPage - 1) * MAX_JOBS_PER_PAGE,
      length: MAX_JOBS_PER_PAGE,
      searchJobWord,
    }).then(async (data) => {
      try {
        if (data['result']) {
          if (data['result'] === 'SUCCESS') {
            setJobItems(data['data']);
            setLoading(false);
            // setSearchJobWord('');

            setTotalItems(data['total']);
          } else if (data['result'] === 'FAILED') {
            setLoading(false);
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    });
  }, [currentPage, searchToggle]);

  useEffect(() => {
    if (headerRef) {
      setHeaderHeight(headerRef?.current?.clientHeight);
    }
  }, [headerRef]);

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    setCurrentPage(current);
  };

  const getLoadingNumber = (start: any, length: any, totalJobCount: any) => {
    console.log(start, length, totalJobCount);

    // let endPoint = start + length;
    // if (endPoint > totalJobCount) {
    //   length = totalJobCount - start;
    // }

    // return length;

    return 6;
  };

  return (
    <div className="p-3">
      <div className="container-fluid">
        <div ref={headerRef} className="container-fluid job-header-div">
          <div className="row">
            <div className="col-sm-12 mb-2">
              <Search
                value={searchJobWord}
                style={{ height: '45px', borderRadius: '2px' }}
                placeholder="Search jobs..."
                onSearch={(value) => {
                  // jobSearchClick(value);

                  setCurrentPage(1);
                  setSearchToggle(!searchToggle);
                }}
                onChange={(e) => {
                  setSearchJobWord(e.target.value);
                }}
                size="large"
                enterButton
                disabled={loading}
                allowClear
              />
            </div>
          </div>

          <div className="row mt-3 mb-2">
            <div className="col-sm-12 center-v-div">
              <h1 className="page-title">Jobs ({totalItems})</h1>

              <Button
                className="job-add-button"
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  setOpenCreateJobModal(true);
                }}
                disabled={loading}
              >
                Add
              </Button>
            </div>
          </div>
        </div>

        {loading ? (
          <>
            <div className="container-fluid jog-list-div" style={{ height: `calc(100vh - ${headerHeight + 100}px)` }}>
              <div className="row">
                <div className="col-sm-12">
                  {Array(MAX_JOBS_PER_PAGE)
                    .fill(null)
                    .map((value, index) => (
                      <JobRowLoadingComponent key={index} />
                    ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="container-fluid jog-list-div" style={{ height: `calc(100vh - ${headerHeight + 100}px)` }}>
              <div className="row">
                <div className="col-sm-12">
                  {jobItems?.map((item: any, index: any) => {
                    return (
                      <div className="job-row-card" key={index} onClick={() => navigate(`${item?.id}`)}>
                        <div className="row">
                          <div className="col-sm-10">
                            <p className="job-row-card-title">{item?.name}</p>
                          </div>
                          <div className="col-sm-2">
                            <div className="j-label">{dateFormatWithDate(item?.created)}</div>
                          </div>
                          {/* <div className="col-sm-2">
                      <div className="j-label">{item?.numOfTags} TAGS</div>
                    </div>
                    <div className="col-sm-2">
                      <div className="j-label">{item?.numOfAreas} AREAS</div>
                    </div>
                    <div className="col-sm-2">
                      <div className="j-label">{item?.numOfParts} PARTS</div>
                    </div> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row mt-3">
                <div className="col-sm-12 d-flex justify-content-center">
                  <Pagination
                    // showSizeChanger
                    // onShowSizeChange={onShowSizeChange}
                    pageSize={MAX_JOBS_PER_PAGE}
                    // showTotal={(total) => `Total ${total} items`}
                    defaultCurrent={currentPage}
                    total={totalItems}
                    onChange={onShowSizeChange}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <Modal isOpen={openCreateJobModal} toggle={() => setOpenCreateJobModal(!openCreateJobModal)}>
          <ModalHeader className="modal-head" toggle={() => setOpenCreateJobModal(!openCreateJobModal)}>
            Add New Job
          </ModalHeader>
          <ModalBody>
            <AddJobForm setOpenCreateJobModal={setOpenCreateJobModal} />
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default JobList;
