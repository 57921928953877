import { createRef, useEffect, useState } from 'react';

import './part-component.css';

export const AreaPartComponent = ({ item, onChangeSelect }: any) => {
  const imgRef: any = createRef();

  const [isActive, setIsActive] = useState(false);

  const [isImgHeightLarge, setIsImgHeightLarge] = useState(false);

  const partOnClick = () => {
    const tempActive = isActive;

    setIsActive(!tempActive);
    onChangeSelect(!tempActive, item?.id);
  };

  useEffect(() => {
    if (imgRef?.current?.clientHeight && imgRef?.current?.clientWidth) {
      if (imgRef?.current?.clientHeight > imgRef?.current?.clientWidth) {
        setIsImgHeightLarge(true);
      } else {
        setIsImgHeightLarge(false);
      }
    }
  }, [imgRef]);

  return (
    <div
      className={`a-part-img-div`}
      onClick={() => {
        partOnClick();
      }}
    >
      <div className={`${isActive ? 'a-part-img-active' : ''}`}></div>
      <img ref={imgRef} className={`a-part-img ${isImgHeightLarge ? 'img-height-100' : 'img-width-100'}`} src={item?.imgUrl} alt="part" />
    </div>
  );
};
