import { createRef, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { FormGroup } from 'reactstrap';
import { loginEnd } from '../../../api/authAPI';
import LandingSideComponent from '../../../components/LandingSideComponent';
import { Button } from 'antd';
import { openNotification } from '../../../meta/globalToaster';

import './login.css';

const Login = () => {
  const loginBodyRef: any = createRef();

  // const [loginBodyWidth, setLoginBodyWidth] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  function successSubmission(data: any) {
    localStorage.setItem('h-kck-auth-firstName', data.firstName);
    localStorage.setItem('h-kck-auth-lastName', data.lastName);
    localStorage.setItem('h-kck-auth-email', data.email);
    localStorage.setItem('h-kck-auth-token', data.token);
    localStorage.setItem('h-kck-auth-role', data.role);
    localStorage.setItem('h-kck-is-valid-auth', 'true');

    window.location.href = '/job';
  }

  function failSubmission(head: any, sub: any) {
    setIsLoading(false);

    openNotification({ message: sub });
  }

  return (
    <div className="login">
      {/* <TopBar /> */}
      <div className="container container-width-set-none">
        <div className="row justify-content-center">
          <div ref={loginBodyRef} className="col-sm-4 login-body" style={{ maxWidth: '370px' }}>
            <div className="container center-login-form">
              <div className="row d-flex justify-content-center">
                <div className="col-sm-12 mb-3 login-img-div">
                  <img className="logo-img" src="/images/logo_white.png" alt="logo" />
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-sm-12">
                  <div className="login-form-div ">
                    <Formik
                      initialValues={{
                        email: '',
                        password: '',
                      }}
                      validationSchema={Yup.object({
                        email: Yup.string()
                          .max(100, 'Email cannot exceed 100 characters')
                          .email('Invalid Email')
                          .required('Required'),
                        password: Yup.string()
                          .min(2, 'Password cannot smaller than 2 characters')
                          .max(60, 'Password cannot exceed 60 characters')
                          .required('Required'),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setIsLoading(true);

                        loginEnd({
                          email: values.email,
                          password: values.password,
                        }).then((data) => {
                          try {
                            if (data['result']) {
                              if (data['result'] === 'SUCCESS') {
                                successSubmission(data['data']);
                              } else if (data['result'] === 'FAILED') {
                                failSubmission('Error!', data['data']['errorMessage']);
                              } else {
                                failSubmission('Error!', 'Please try again later!');
                              }
                            } else {
                              failSubmission('Error!', 'Please try again later!');
                            }
                          } catch (error) {
                            failSubmission('Error!', 'Please try again later!');
                          }
                        });
                      }}
                    >
                      <Form>
                        <div className="row d-flex justify-content-center">
                          <div className="col-sm-12 mb-4 p-5">
                            <div className="row">
                              <div className="col-sm-12 mb-5 mt-4">
                                <h5 className="l-head-text">Sign in</h5>
                                <p className="l-sub-text">Make labels</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12 mb-3">
                                <p className="input-label">Email</p>
                                <FormGroup className="input-box">
                                  <Field
                                    name="email"
                                    type="text"
                                    className="form-control l-input-box"
                                    placeholder="Email"
                                    disabled={isLoading}
                                  />
                                  <ErrorMessage name="email">
                                    {(msg: any) => <div style={{ color: 'red' }}>{msg}</div>}
                                  </ErrorMessage>
                                </FormGroup>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12 mb-3">
                                <p className="input-label">Password</p>
                                <FormGroup className="input-box">
                                  <Field
                                    name="password"
                                    type="password"
                                    className="form-control l-input-box"
                                    placeholder="Password"
                                    disabled={isLoading}
                                  />
                                  <ErrorMessage name="password">
                                    {(msg: any) => <div style={{ color: 'red' }}>{msg}</div>}
                                  </ErrorMessage>
                                </FormGroup>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-12 text-center">
                                <Button
                                  className="button-submit-style"
                                  type="primary"
                                  style={{ width: '100%' }}
                                  loading={isLoading}
                                  size="large"
                                  htmlType="submit"
                                >
                                  Sign in
                                </Button>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-12 mt-4">
                                <p className="l-sub-text">
                                  By selecting Sign in, you agree to our Terms and acknowledge our Privacy Statement.
                                </p>
                              </div>
                              <div className="col-sm-12 mt-3">
                                <p className="l-sub-text">
                                  Need something else? | <a href="">Create an account</a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <LandingSideComponent /> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
