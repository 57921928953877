import { DatePicker, DatePickerProps } from 'antd';
import { useField, useFormikContext } from 'formik';

export const DatePickerField = ({ ...props }: any) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date);

    setFieldValue(field.name, date);
  };

  return (
    <DatePicker
      {...field}
      {...props}
      defaultValue={(field.value && new Date(field.value)) || null}
      onChange={onChange}
    />
  );
};
