import { UncontrolledTooltip } from 'reactstrap';

export default function renderPlayingState(state: any) {
  const userId = 'id';
  switch (state) {
    case 'A':
      return (
        <>
          <h3>
            <span className="ui green label" id={userId}>
              A
            </span>
          </h3>
          {/* <UncontrolledTooltip placement="top" target={userId}>
            Playing
          </UncontrolledTooltip> */}
        </>
      );
    case 'D':
      return (
        <>
          <h3>
            <span className="ui red label" id={userId}>
              D
            </span>
          </h3>
          {/* <UncontrolledTooltip placement="top" target={userId}>
            Injured
          </UncontrolledTooltip> */}
        </>
      );
    default:
      return (
        <>
          <h3>
            <span className="ui teal label" id={userId}>
              NM
            </span>
          </h3>
          {/* <UncontrolledTooltip placement="top" target={userId}>
            Not Mention
          </UncontrolledTooltip> */}
        </>
      );
  }
}

export function renderGameStatus(role: any) {
  switch (role) {
    case 'START':
      return (
        <h3>
          <span className="ui green label">Processing</span>
        </h3>
      );

    case 'END':
      return (
        <h3>
          <span className="ui red label">Done</span>
        </h3>
      );

    case 'PENDING':
      return (
        <h3>
          <span className="ui orange label">Pending</span>
        </h3>
      );

    default:
      return (
        <h3>
          <span className="ui teal label">Not Mention</span>
        </h3>
      );
  }
}
