import axios from 'axios';

export const readAllArea = async () => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/area/read_all.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readActiveAreas = async () => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/area/read_by_user.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const AddArea = async ({ jobId, name, partList }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('jobId', jobId);
    formData.append('name', name);
    formData.append('partList', partList);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/area/create.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const editArea = async ({ id, name }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('id', id);
    formData.append('name', name);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/area/edit.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readActiveAreasByJob = async ({ jobId }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('jobId', jobId);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/area/read_by_job.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const deleteAreaAPI = async ({ areaId }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('areaId', areaId);

    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/area/delete.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
