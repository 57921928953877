import './message-component.css';

export const MessageComponent = ({ message = 'No Data' }: any) => {
  return (
    <div className="row m-div p-3">
      <div className="col-sm-12">
        <img src="/icons/empty_icon.svg" alt="icon" className="m-image" />
        <p className="m-text">{message}</p>
      </div>
    </div>
  );
};
