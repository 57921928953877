export default function renderUserLevel(role: any) {
  switch (role) {
    case 'ADMIN':
      return (
        <h3>
          <span className="ui orange label">Admin</span>
        </h3>
      );

    case 'MEMBER':
      return (
        <h3>
          <span className="ui red label">Member</span>
        </h3>
      );
    default:
      return (
        <h3>
          <span className="ui teal label">Not Mention</span>
        </h3>
      );
  }
}
