import { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Alert, FormGroup } from 'reactstrap';
import { AddJob } from '../../../api/jobAPI';
import { Button } from 'antd';
import { DatePickerField } from '../../../components/Input/DatePickerField';
import dayjs from 'dayjs';

const AddJobForm = ({ setOpenCreateJobModal }: any) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: '', sub: '' });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    setOpenCreateJobModal(false);
    window.location.href = '/job';
  }

  function failSubmission(head: any, sub: any) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="invoice-form-div">
            <Formik
              initialValues={{
                name: '',
                installedDate: '',
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .min(2, 'name cannot smaller than 2 characters')
                  .max(50, 'name cannot exceed 50 characters')
                  .required('Required'),
                installedDate: Yup.string().required('Required'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                AddJob({
                  name: values.name,
                  installedDate: dayjs(values.installedDate).format('YYYY-MM-DD'),
                }).then((data) => {
                  try {
                    if (data['result']) {
                      if (data['result'] === 'SUCCESS') {
                        successSubmission();
                      } else if (data['result'] === 'FAILED') {
                        failSubmission('¡Error!', data['data']['errorMessage']);
                      } else {
                        failSubmission('Error!', 'Please try again later!');
                      }
                    } else {
                      failSubmission('Error!', 'Please try again later!');
                    }
                  } catch (error) {
                    failSubmission('Error!', 'Please try again later!');
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mt-4 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Job Name"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="name">
                            {(msg: any) => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <DatePickerField className="form-control" name="installedDate" placeholder="Installed Date" />
                          <ErrorMessage name="installedDate">
                            {(msg: any) => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>

                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                {/* <p>{errorMsg.head}</p>
                                <hr /> */}
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            style={{ width: '100%' }}
                            loading={isLoading}
                          >
                            Add new Job
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddJobForm;
