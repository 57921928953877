import { createRef, useEffect, useState } from 'react';
import { Tag, Tooltip } from 'antd';

import './part-component.css';

export const ExportPartComponent = ({ item, onChangeSelect }: any) => {
  const imgRef: any = createRef();

  const [isActive, setIsActive] = useState(false);

  const [isImgHeightLarge, setIsImgHeightLarge] = useState(false);

  const partOnClick = () => {
    const tempActive = isActive;

    setIsActive(!tempActive);
    onChangeSelect(!tempActive, item?.imgUrl, item?.id);
  };

  useEffect(() => {
    if (imgRef?.current?.clientHeight && imgRef?.current?.clientWidth) {
      if (imgRef?.current?.clientHeight > imgRef?.current?.clientWidth) {
        setIsImgHeightLarge(true);
      } else {
        setIsImgHeightLarge(false);
      }
    }
  }, [imgRef]);

  return (
    <div
      className={`a-part-img-div`}
      onClick={() => {
        partOnClick();
      }}
    >
      {item?.note && (
        <Tooltip className="note-label" title={item?.note} color={'red'}>
          <Tag color="black">note</Tag>
        </Tooltip>
      )}

      {item?.isPrinted === 1 && (
        <Tag className="e-printed-label" color="black">
          Printed
        </Tag>
      )}

      <div className={`${isActive ? 'a-part-img-active' : ''}`}></div>
      <img
        ref={imgRef}
        className={`a-part-img ${isImgHeightLarge ? 'img-height-100' : 'img-width-100'}`}
        src={item?.imgUrl}
        alt="part"
      />
    </div>
  );
};
