import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ProtectedLayout } from './layout/ProtectedLayout';
import { tokenCheck } from '../api/authAPI';

function Protected({ children }: any) {
  const isAuthenticated = localStorage.getItem('h-kck-is-valid-auth');
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('h-kck-auth-token');
    if (token) {
      tokenCheck({
        token: token,
      }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              if (data['data']) {
                setUserId(data['data'].id);
              }

              localStorage.setItem('h-kck-is-user-id', data['data']?.id);
              localStorage.setItem('h-kck-is-valid-auth', 'true');
            } else if (data['result'] === 'FAILED') {
              localStorage.setItem('h-kck-is-valid-auth', 'false');
              window.location.href = '/login';
            } else {
              localStorage.setItem('h-kck-is-valid-auth', 'false');
              window.location.href = '/login';
            }
          } else {
            localStorage.setItem('h-kck-is-valid-auth', 'false');
            window.location.href = '/login';
          }
        } catch (error) {
          localStorage.setItem('h-kck-is-valid-auth', 'false');
          window.location.href = '/login';
        }
      });
    } else {
      localStorage.setItem('h-kck-is-valid-auth', 'false');
      window.location.href = '/login';
    }
  });

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return <ProtectedLayout>{children}</ProtectedLayout>;
}

export default Protected;
