import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Alert, FormGroup } from 'reactstrap';
import { editArea } from '../../../api/areaAPI';
import { Button } from 'antd';
import { openNotification } from '../../../meta/globalToaster';

const EditAreaForm = ({ clickedArea, editAreaSuccess }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    editAreaSuccess(false);
    setIsLoading(false);
  }

  function failSubmission(head: any, sub: any) {
    setIsLoading(false);
    openNotification({ message: sub });
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="invoice-form-div">
            <Formik
              initialValues={{
                id: clickedArea && clickedArea.id,
                name: clickedArea && clickedArea.name,
              }}
              validationSchema={Yup.object({
                name: Yup.string().required('Required'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);

                editArea({
                  id: values.id,
                  name: values.name,
                }).then((data: any) => {
                  try {
                    if (data['result']) {
                      if (data['result'] === 'SUCCESS') {
                        successSubmission();
                      } else if (data['result'] === 'FAILED') {
                        failSubmission('¡Error!', data['data']['errorMessage']);
                      } else {
                        failSubmission('Error!', 'Please try again later!');
                      }
                    } else {
                      failSubmission('Error!', 'Please try again later!');
                    }
                  } catch (error) {
                    failSubmission('Error!', 'Please try again later!');
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field name="name" type="text" className="form-control" placeholder="Area Name" disabled={isLoading} />
                          <ErrorMessage name="name">{(msg: any) => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <Button type="primary" size="large" style={{ width: '100%' }} htmlType="submit" loading={isLoading}>
                            Update Area
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAreaForm;
