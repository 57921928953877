import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Alert, FormGroup } from 'reactstrap';

import { updateUserRoleState } from '../../../../api/authAPI';
import { Button } from 'antd';

const EditRoleState = ({ userObject, setOpenEditModal }: any) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: '', sub: '' });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    setOpenEditModal(false);
    window.location.href = '/user';
  }

  function failSubmission(head: any, sub: any) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="invoice-form-div">
            <Formik
              initialValues={{
                uniqueId: userObject && userObject.userId,
                role: userObject && userObject.role,
                active: userObject && userObject.active,
              }}
              validationSchema={Yup.object({
                role: Yup.string().required('Required'),
                active: Yup.string().required('Required'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);
                updateUserRoleState({
                  uniqueId: values.uniqueId,
                  role: values.role,
                  active: values.active,
                }).then((data: any) => {
                  try {
                    if (data['result']) {
                      if (data['result'] === 'SUCCESS') {
                        successSubmission();
                      } else if (data['result'] === 'FAILED') {
                        failSubmission('¡Error!', data['data']['errorMessage']);
                      } else {
                        failSubmission('Error!', 'Please try again later!');
                      }
                    } else {
                      failSubmission('Error!', 'Please try again later!');
                    }
                  } catch (error) {
                    failSubmission('Error!', 'Please try again later!');
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field name="role" as="select" className="form-control" placeholder="Select user Role" disabled={isLoading}>
                            <option value="ADMIN">ADMIN</option>
                            <option value="MEMBER">MEMBER</option>
                          </Field>
                          <ErrorMessage name="role">{(msg: any) => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field name="active" as="select" className="form-control" placeholder="Select Playing State" disabled={isLoading}>
                            <option value="A">Activate User</option>
                            <option value="D">Delete User</option>
                          </Field>
                          <ErrorMessage name="active">{(msg: any) => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                {/* <p>{errorMsg.head}</p>
                                <hr /> */}
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <Button type="primary" htmlType="submit" size="large" style={{ width: '100%' }} loading={isLoading}>
                            Update User
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRoleState;
