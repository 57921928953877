import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Protected from './components/Protected';

// import Login from './pages/auth/login/Login';
import AllUsers from './pages/auth/allUsers/AllUsers';
import JobList from './pages/job/job-list/JobList';

import { JobPage } from './pages/job/job-page/JobPage';
import { UserDetails } from './pages/auth/user-details/UserDetails';
import { SettingsPage } from './pages/settings/SettingsPage';
import { HomePage } from './pages/home-page/HomePage';
import Login from './pages/auth/login/Login';
// import LoginOld from './pages/auth/login/Login.old';

import 'react-toastify/dist/ReactToastify.css';
import './styles/global.css';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route
          path="/"
          element={
            <Protected>
              <HomePage />
            </Protected>
          }
        />
        <Route
          path="/user"
          element={
            <Protected>
              <AllUsers />
            </Protected>
          }
        />
        <Route
          path="/user/:userId"
          element={
            <Protected>
              <UserDetails />
            </Protected>
          }
        />
        <Route
          path="/job"
          element={
            <Protected>
              <JobList />
            </Protected>
          }
        />
        <Route
          path="/job/:jobId"
          element={
            <Protected>
              <JobPage />
            </Protected>
          }
        />
        <Route
          path="/setting"
          element={
            <Protected>
              <SettingsPage />
            </Protected>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
