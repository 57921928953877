import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { InboxOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { Alert, FormGroup } from 'reactstrap';
import { Button, Select, Spin, Upload, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { AddPlan } from '../../../api/planAPI';

import ReactS3Client from 'react-aws-s3-typescript';
import { openNotification } from '../../../meta/globalToaster';
import { LoadingComponent } from '../../../components/loading-component/LoadingComponent';

const s3Config: any = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS,
  secretAccessKey: process.env.REACT_APP_SECRET,
  // dirName: 'uploads',
  // s3Url: 'http://shape-extractor.s3-us-east-1.amazonaws.com',
};

const { Dragger } = Upload;

const AddPlanForm = ({ jobId, updatedPlanList }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    updatedPlanList();
    setIsLoading(false);
  }

  function failSubmission(head: any, sub: any) {
    setIsLoading(false);
    openNotification({ message: sub });
  }

  const uploadFile = async (file: any, fileName: string) => {
    /* Import s3 config object and call the constrcutor */
    const s3 = new ReactS3Client(s3Config);

    try {
      setIsLoading(true);
      const res = await s3.uploadFile(file);

      if (res?.location) {
        AddPlan({
          jobId: jobId,
          imgUrl: res?.location,
        }).then((data) => {
          try {
            if (data['result']) {
              if (data['result'] === 'SUCCESS') {
                successSubmission();
              } else if (data['result'] === 'FAILED') {
                failSubmission('¡Error!', data['data']['errorMessage']);
              } else {
                failSubmission('Error!', 'Please try again later!');
              }
            } else {
              failSubmission('Error!', 'Please try again later!');
            }
          } catch (error) {
            failSubmission('Error!', 'Please try again later!');
          }
        });
      } else {
        setIsLoading(false);
      }
    } catch (exception) {
      setIsLoading(false);
    }
  };

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    customRequest({ file }: any) {
      const fileName = file.name;

      //API END
      uploadFile(file, fileName);
    },

    showUploadList: true,
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          {isLoading ? (
            <LoadingComponent />
          ) : (
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single upload. Strictly prohibited from uploading company data or other banned files.
              </p>
            </Dragger>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddPlanForm;
