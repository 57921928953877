import { Button } from 'antd';

import { useEffect, useState } from 'react';

import { bulkPartDelete } from '../../../api/planAPI';
import { openNotification } from '../../../meta/globalToaster';
import { NOTIFICATION_TYPES } from '../../../const/notificationTypes';
import { MessageComponent } from '../../../components/message-component/MessageComponent';
import { DeletePartComponent } from '../../../components/part-component/DeletePartComponent';

export const PartDeleteComponent = ({ partItems, partDeleteSuccess }: any) => {
  const [partItemList, setPartItemList] = useState<any>([]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  const [selectedIdList, setSelectedIdList] = useState<any>([]);

  useEffect(() => {
    if (partItems && partItems?.length > 0) {
      setPartItemList(partItems);
      setIsEmpty(false);
    } else {
      setPartItemList([]);
      setIsEmpty(true);
    }
  }, [partItems]);

  const onChangeSelect = (value: string, id: any) => {
    if (value) {
      setSelectedIdList([...selectedIdList, id]);
    } else {
      var indexI = selectedIdList.indexOf(id);
      if (indexI > -1) {
        selectedIdList.splice(indexI, 1);
      }
    }
  };

  const successSubmission = () => {
    setSubmitLoading(false);

    setSelectedIdList([]);
    partDeleteSuccess();
  };

  const failSubmission = (message: any) => {
    setSubmitLoading(false);
    openNotification({ message });
  };

  const updateExportClick = async () => {
    if (selectedIdList?.length > 0) {
      setSubmitLoading(true);
      bulkPartDelete({
        idList: selectedIdList,
      }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              successSubmission();
            } else if (data['result'] === 'FAILED') {
              failSubmission(data['data']['errorMessage']);
            } else {
              failSubmission('Please try again later!');
            }
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      openNotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Select at least one Part' });
    }
  };

  return (
    <div>
      <>
        {isEmpty ? (
          <MessageComponent />
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-sm-12 mb-2">
                <Button
                  style={{ float: 'right' }}
                  type="primary"
                  //   icon={<PoweroffOutlined />}
                  loading={submitLoading}
                  onClick={() => updateExportClick()}
                >
                  Delete selected parts
                </Button>
              </div>
            </div>
            <div className="row print-part-list-div">
              {partItemList?.map((item: any, i: any) => {
                return (
                  <div key={i} className="col-sm-2 m-0 p-1">
                    <DeletePartComponent item={item} onChangeSelect={onChangeSelect} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </>
    </div>
  );
};
