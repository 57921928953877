import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { readByUserId } from '../../../api/authAPI';
import { Tag } from 'antd';

import { LoadingComponent } from '../../../components/loading-component/LoadingComponent';
import { MessageComponent } from '../../../components/message-component/MessageComponent';

import './user-details.css';

export const UserDetails = () => {
  const { userId } = useParams();

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<any>({});

  useEffect(() => {
    setLoading(true);
    if (userId) {
      readByUserId({ userId }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              const saveId = localStorage.getItem('h-kck-is-user-id');
              if (saveId === userId) {
                setUserData(data['data']);
              } else {
              }

              setLoading(false);
            } else if (data['result'] === 'FAILED') {
              setLoading(false);
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      });
    }
  }, [userId]);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {userData?.userId ? (
            <div className="container">
              <div className="row mt-3">
                <div className="col-sm-12">
                  <p className="p-head-text">Profile</p>
                </div>
              </div>

              <div className="profile-row-card">
                <div className="row">
                  <div className="col-sm-3">
                    <p className="s-key-text">Email</p>
                  </div>
                  <div className="col-sm-9">
                    <Tag className="s-key-text" color="#F2F4F7">
                      {userData?.email}
                    </Tag>
                  </div>
                </div>
              </div>

              <div className="profile-row-card">
                <div className="row">
                  <div className="col-sm-3">
                    <p className="s-key-text">Password</p>
                  </div>
                  <div className="col-sm-9">
                    <Tag className="s-key-text" color="#F2F4F7">
                      ***************
                    </Tag>
                  </div>
                </div>
              </div>

              <div className="profile-row-card">
                <div className="row">
                  <div className="col-sm-3">
                    <p className="s-key-text">First name</p>
                  </div>
                  <div className="col-sm-9">
                    <Tag className="s-key-text" color="#F2F4F7">
                      {userData?.firstName}
                    </Tag>
                  </div>
                </div>
              </div>

              <div className="profile-row-card">
                <div className="row">
                  <div className="col-sm-3">
                    <p className="s-key-text">Last name</p>
                  </div>
                  <div className="col-sm-9">
                    <Tag className="s-key-text" color="#F2F4F7">
                      {userData?.lastName}
                    </Tag>
                  </div>
                </div>
              </div>

              <div className="profile-row-card">
                <div className="row">
                  <div className="col-sm-3">
                    <p className="s-key-text">Age</p>
                  </div>
                  <div className="col-sm-9">
                    <Tag className="s-key-text" color="#F2F4F7">
                      {userData?.age ? userData?.age : '-'}
                    </Tag>
                  </div>
                </div>
              </div>

              <div className="profile-row-card">
                <div className="row">
                  <div className="col-sm-3">
                    <p className="s-key-text">Company Name</p>
                  </div>
                  <div className="col-sm-9">
                    <Tag className="s-key-text" color="#F2F4F7">
                      {userData?.companyName ? userData?.companyName : '-'}
                    </Tag>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container mt-5">
              <MessageComponent message={'No Profile data'} />
            </div>
          )}
        </>
      )}
    </>
  );
};
