import { Skeleton } from 'antd';

import './loading-component.css';

export const LoadingComponent = ({ message = 'Loading...', bar = false }: any) => {
  return (
    <>
      {bar ? (
        <div className="row p-3">
          <div className="col-sm-12">
            <Skeleton.Button active={true} block={true} />
          </div>
        </div>
      ) : (
        <div className="row l-div p-3">
          <div className="col-sm-12">
            <Skeleton />
          </div>
        </div>
      )}
    </>
  );
};

export const JobRowLoadingComponent = () => {
  return (
    <div className="load-job-row-card">
      <div className="row">
        <div className="col-sm-10">
          <p className="job-row-card-title">
            <Skeleton.Button active={true} block={true} size={'small'} />
          </p>
        </div>
        <div className="col-sm-2">
          <div className="j-label">
            <Skeleton.Button active={true} block={true} size={'small'} />
          </div>
        </div>
      </div>
    </div>
    // <div className="row">
    //   <div className="col-sm-12">
    //     <div className="job-loading-card">
    //       <div className="row">
    //         <div className="col-sm-10">
    //           <Skeleton.Button active={true} block={true} />
    //         </div>
    //         <div className="col-sm-2">
    //           <Skeleton.Button active={true} block={true} />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};
